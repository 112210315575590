import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});


	// Projecten filters
	function projectsFilters(){
		$('.project-filter-names a').on('click', function(e){

			var hash = $(this).attr('href');
			hash = hash.replace(/^#/, '');

			$('.project-filter-names a').each(function(){
				$(this).removeClass('active');
			});	

			$(e.target).addClass('active');

			$('.grid-item').each(function(index){
				if($(this).hasClass(hash)){
					$(this).addClass('reveal');
				}else{
					$(this).removeClass('reveal');
				}
			});

		});		
	}

	projectsFilters();


});